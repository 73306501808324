<template>
    <div>
        <div class="forgot_container">
            <div class="forgot_left-side">
                <router-link to="/">
                    <div class="forgot_title">

                    </div>
                </router-link>
                <div class="forgot_left-content">
                </div>
            </div>

            <div class="forgot_right-side">
                <div class="account_text">
                    <div class="dont_have">
                        Don't have an account?
                        <router-link to="/register"><span class="sign_text"> Sign Up</span></router-link>
                    </div>
                </div>

                <div class="forgot_right-side-content">
                    <div class="welcome_title">
                        Forgot password
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.132" height="37.224"
                             viewBox="0 0 32.132 37.224">
                            <defs>
                            </defs>
                            <g transform="translate(-34.88)">
                                <path class="a_lock"
                                      d="M92.078,11.506a7.948,7.948,0,1,1,15.9,0v3.749h3.558V11.506a11.506,11.506,0,1,0-23.012,0v3.749h3.558Z"
                                      transform="translate(-49.725)"/>
                                <path class="b_lock"
                                      d="M65.552,239.02H36.34a1.459,1.459,0,0,0-1.46,1.46v16.859a1.459,1.459,0,0,0,1.46,1.46H65.552a1.459,1.459,0,0,0,1.46-1.46V240.48A1.459,1.459,0,0,0,65.552,239.02ZM52.041,254.912a1.095,1.095,0,1,1-2.19,0v-.1a1.095,1.095,0,1,1,2.19,0Zm.15-5.262a.208.208,0,0,0-.15.193v.969a1.095,1.095,0,1,1-2.19,0v-.969a2.394,2.394,0,0,1,1.659-2.275,1.813,1.813,0,1,0-2.377-1.723,1.095,1.095,0,1,1-2.19,0,4,4,0,1,1,5.247,3.8Z"
                                      transform="translate(0 -221.575)"/>
                            </g>
                        </svg>
                    </div>

                    <div class="input_item form-group">
                        <b-form-input
                                id="email"
                                v-model="email"
                                type="email"
                                disabled
                                class="mb-3"
                                trim
                        ></b-form-input>
                    </div>
                    <div class="input_item form-group">
                        <label>Password</label>
                        <b-form-input
                                id="password"
                                :type="r_pass"
                                v-model="newPassword"
                                trim
                        ></b-form-input>
                        <div class="eye_close" @click="showPassword('r_pass')"></div>
                        <!--                            <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">-->
                        <!--                                <span v-if="!$v.userForm.password.required">Password field is required</span>-->
                        <!--                                <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>-->
                        <!--                            </div>-->
                    </div>
                    <div class="input_item form-group">
                        <label>Confirm</label>
                        <b-form-input
                                id="confirm"
                                :type="r_confirm"
                                v-model="confirmPassword"
                                trim
                        ></b-form-input>
                        <div class="eye_close" @click="showPassword('r_confirm')"></div>
                        <div v-if="success_msg" class="success_msg">
                            {{success_msg}}
                        </div>
                    </div>
                    <b-button class="forgot_btn" @click="sendPassword">Update</b-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "ForgotPassword",
        data() {
            return {
                newPassword: '',
                confirmPassword: '',
                token: '',
                email: '',
                r_pass: 'password',
                r_confirm: 'password',
                success_msg: ''
            }
        },
        mounted() {
            this.email = this.$route.query.email
            this.token = this.$route.query.token
        },
        methods: {
            ...mapActions(['resetPassword']),
            sendPassword() {
                let data = {
                    newPassword: this.newPassword,
                    confirmPassword: this.confirmPassword,
                    token: this.token,
                    email: this.email
                }
                this.resetPassword(data)
                    .then((res) => {
                        if(res.status === 200) {
                            this.success_msg = res.data.success
                            setTimeout(() => {
                                this.success_msg = ''
                                this.$router.push('/login')
                            }, 5000)
                        }
                    })
            },
            showPassword(item) {
                if (item === 'r_pass') {
                    if (this.r_pass === 'password') {
                        this.r_pass = 'text'
                    } else {
                        this.r_pass = 'password'
                    }
                } else {
                    if (this.r_confirm === 'password') {
                        this.r_confirm = 'text'
                    } else {
                        this.r_confirm = 'password'
                    }
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .forgot {
        &_container {
            width: 100%;
            height: 100vh;
            border: 1px solid lightgray;
            background: #fff;
            display: flex;
        }

        &_left-side {
            width: 50%;
            padding: 55px 0 0 140px;
            display: flex;
            flex-direction: column;
            height: 100%;
            @media screen and (max-width: 1440px) {
                padding: 65px 65px 0 140px;
            }
            @media screen and (max-width: 1200px) {
                padding: 30px 40px 0 60px;
            }

            & a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &_right-side {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 1200px) {
                height: 83vh;
            }

            & .account_text {
                width: 100%;
                padding: 50px 150px 10px;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 1440px) {
                    padding: 30px 90px;
                }

                & .dont_have {
                    color: $black;
                    font-weight: 500;
                    font-size: 18px;
                    @media screen and (max-width: 1440px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 14px;
                    }

                    & .sign_text {
                        color: $light_blue;
                        border-bottom: 1px solid $light_blue;

                    }
                }
            }

            &-content {
                max-width: 550px;
                width: 100%;
                height: 100%;
                display: flex;
                padding: 0 40px 40px 0;
                margin-right: 40px;
                justify-content: center;
                flex-direction: column;
                @media screen and (max-width: 1440px) {
                    padding: 0 20px 60px;
                }
                @media screen and (max-width: 1200px) {
                    max-width: 450px;
                }

                & .welcome_title {
                    font-size: 35px;
                    font-weight: 600;
                    color: $darkBlue;
                    padding: 15px 0;
                    @media screen and (max-width: 1440px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 30px;
                        padding: 15px 0;
                    }
                    & .a_lock {
                        fill: $main_blue;
                    }
                    & .b_lock {
                        fill: $light_blue;
                    }

                    & svg {
                        margin-left: 30px;
                    }

                }

                & .input_item {
                    width: 100%;
                    margin-top: 20px;
                    position: relative;

                    & .eye_close {
                        position: absolute;
                        width: 30px;
                        height: 36px;
                        right: 15px;
                        top: 33px;
                        background-image: url("~@/assets/images/eye_close.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        cursor: pointer;
                    }

                    & label {
                        color: $darkBlue;
                        font-weight: 600;
                        font-size: 16px;
                    }

                    & input {
                        background: $blue_grey 0 0 no-repeat padding-box;
                        border: none;

                        &:focus {
                            box-shadow: none;
                            border: 1px solid $light_blue;
                            border-radius: 3px;
                        }
                    }
                    & .success_msg {
                        padding-top: 10px;
                        color: green;
                        font-size: 18px;
                    }
                }

                & .forgot_btn {
                    width: 100%;
                    height: 60px;
                    background: $light_blue;
                    border-radius: 10px;
                    color: $white;
                    font-weight: 600;
                    border: none;
                    margin-top: 30px;
                    @media screen and (max-width: 1440px) {
                        height: 50px;
                    }
                }

                & .back_website {
                    padding-top: 20px;
                    color: $main_blue;

                    & svg {
                        margin-right: 15px;
                    }
                }
            }
        }

        &_title {
            max-width: 220px;
            width: 100%;
            height: 30px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover !important;
            background: url("~@/assets/images/Logo.svg");
        }

        &_left-content {
            padding: 30px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: url("~@/assets/images/login_img1.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @media screen and (max-width: 1440px) {
                padding: 110px 20px;
            }
            @media screen and (max-width: 1200px) {
                padding: 70px 20px;
            }
        }
    }

</style>